@import "../fonts/WEB/css/satoshi.scss";

$primary-color: #00BCF3;
$primary-color-light: #C9F3FF;
$text-color: #252525;
$text-secondary: #848484;
$success-color: #2ACE29;
$text-success: #2ACE29;
$text-danger: #F13134;
$text-info: #FFAA01;
$dark_blue: #166598;

$font-bold: 'Satoshi-Bold';
$font-medium: 'Satoshi-Medium';
$font-light: 'Satoshi-Light';
