
$gutter: 1rem;
@import "assets/styles/layout/layout.scss";
@import "./assets/styles/variables";
@import "./assets/fonts/WEB/css/satoshi.scss";

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeng/resources/themes/lara-light-blue/theme.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

h1, h2 , h3, h4, h5, h6, th {
    font-family: $font-bold;
}

p, span, small, label, tr, td, textarea {
    font-family: $font-medium;
}

label {
    font-weight: 400;
}

.p-error {
    color: #e24c4c;
    font-size: 14px;
}

.text-success {
    color: $text-success;
}

.text-danger {
    color: $text-danger;
}

.text-info {
    color: $text-info;
}
.primary-text{
     color:$primary-color !important ;
}
.p-menu-overlay {
    top: 50px !important;
}

.p-button {
    background: $primary-color !important;
    border: 1px solid $primary-color !important;
    padding: 0.7rem 1.25rem !important;
    border-radius: 0 !important;
    color: #fff !important;
    flex-direction: row-reverse;
    gap: 7px;
    &:enabled:hover {
        background: $primary-color !important;
        border-color: $primary-color !important;
    }

    &:enabled:active {
        background: $primary-color !important;
        border-color: $primary-color !important;
    }
    &:focus {
        box-shadow: none !important;
    }
    &:hover {
        transition: opacity 0.5s;
        opacity: 0.85;
    }
}

.p-button .p-button-icon-right {
    margin-left: 0 !important;
}

.p-inputtext:enabled:hover {
    border-color: #ced4da !important;
}

.p-inputtext:enabled:focus {
    border-color: #ced4da !important;
    box-shadow: none !important;
}

.p-menu .p-menuitem-link:focus {
    box-shadow: none !important;
}

.p-dropdown {
    border: 1px solid #C7C7C7 !important;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #ced4da !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

.p-multiselect {
    border: 1px solid #C7C7C7 !important;
    height: 45px !important;
    margin-bottom: 5px !important;

}

.p-multiselect:not(.p-disabled):hover {
    border-color: #ced4da !important;
}

.p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    margin-bottom: 5px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    margin-bottom: 5px !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight{
    color: $primary-color !important;
    background: #EFF6FF !important;
    margin-bottom: 5px;
}






// here





.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    color: $primary-color !important;

}




.did-floating-label-content {
    position: relative;
    i {
        color: $text-color !important;
        font-weight: bold;
        margin-top: -0.4rem !important;
        right: 0;
        margin-right: 10px;
    }
}


.did-floating-label {
    color: #495057;
    font-size: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 8px;
    top: 13px;
    padding: 0 5px;
    background: #fff;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    font-family: "Satoshi-medium";
}

.did-floating-input,
.did-floating-select {
    font-size: 12px;
    display: block;
    width: 100%;
    height: 45px;
    padding: 0 30px 0 12px;
    background: #fff;
    color: $text-color;
    border: 1px solid #C7C7C7;
    border-radius: 4px;
    box-sizing: border-box;

    &:focus {
        outline: none;
        border: 1px solid #C7C7C7;
        ~.did-floating-label {
            top: -8px;
            font-size: 13px;
        }
        ~i {
            color: $text-color !important;
        }
    }

}

.p-checkbox .p-checkbox-box {
    border: 1px solid #707070 !important;
    border-radius: 3px !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
    border-color: $primary-color !important;
    background: $primary-color !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: $primary-color !important;
    background: $primary-color !important;
}

.did-floating-input:not(:placeholder-shown)~.did-floating-label {
    top: -8px;
    font-size: 13px;
}

.did-floating-select:not([value=""]):valid~.did-floating-label {
    top: -8px;
    font-size: 13px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    text-transform: capitalize;
}

.p-dropdown .p-dropdown-label {
    text-transform: capitalize;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: $primary-color !important;
    background: #C9F3FF !important;
}

.p-tabview .p-tabview-nav {
    border: 0px solid #dee2e6 !important;
    border-width: 0 0 0 0 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-weight: normal !important;
    font-size: 16px !important;
    padding: 0.8rem 1.25rem !important;
    border: 1px solid #C7C7C7 !important;
    border-bottom: 1px solid #C7C7C7 !important;
    border-radius: 0px;
    margin: 0 !important;
}

.p-tabview .p-tabview-nav li:first-child .p-tabview-nav-link {
    border-radius: 6px 0px 0px 6px;
}

.p-tabview .p-tabview-nav li:nth-child(2) .p-tabview-nav-link {
    border-radius: 0px;
}

.p-tabview .p-tabview-nav li:nth-child(3) .p-tabview-nav-link {
    border-radius: 0px 6px 6px 0px;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    border-color: $primary-color !important;
    border-width: 1px !important;
    color: #fff !important;
    background-color: $primary-color !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none !important;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    color: $text-secondary !important;
}

.p-datatable .p-datatable-header {
    border: none !important;
    background-color: transparent !important;
    padding: 0 !important;
}

.p-datatable .p-datatable-thead > tr > th {
    background: #fff !important;
    font-size: 15px !important;
    color: $text-color !important;
    padding: 0.8rem !important;
    border-width: 1px 0 1px 0 !important;
}

.p-datatable .p-datatable-tbody > tr > td {
    color: $text-color !important;
    padding: 0.8rem !important;
}

.p-datatable .p-datatable-tbody > tr:last-child > td {
    border: none !important;
}

.p-dialog-mask {
    background-color: rgba($color: #000000, $alpha: 0.5);
}

.disabled-input {
    input {
        opacity: 0.5;
        cursor: default !important;
        pointer-events: none;
    }
    .p-dropdown {
        opacity: 0.5;
        cursor: default !important;
        pointer-events: none;
    }
}


.coming_soon {
    height: calc(100vh - 10rem);
    display: flex;
    justify-content: center;
    align-items: center;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.visibility-hidden {
    visibility: hidden;
}

.p-float-label input:focus~label, .p-float-label input.p-filled~label, .p-float-label textarea:focus~label, .p-float-label textarea.p-filled~label, .p-float-label .p-inputwrapper-focus~label, .p-float-label .p-inputwrapper-filled~label {
    top: 0.1rem !important;
    background: #fff !important;
    padding: 0 5px !important;
}

.primary-text{
    color:$primary-color !important ;
}

.p-breadcrumb {
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;

}
.p-breadcrumb ul li .p-menuitem-link .p-menuitem-text {
    color: $primary-color !important; 
}

.p-breadcrumb ul li:last-child .p-menuitem-link .p-menuitem-text {
    color: black !important;
    text-decoration: none !important;
    font-weight: 9010; 
}

.p-breadcrumb ul li.p-breadcrumb-chevron {
    color: black !important;
    font-size: 12px;
}

.p-breadcrumb ul li .p-menuitem-link .p-menuitem-icon {
    color: $primary-color; 
}

.p-progressbar{
    height: 0.5rem!important;
}
.p-progressbar .p-progressbar-value{
    background: $primary-color !important;
}	
