@import "../variables";

.layout-sidebar {
    position: fixed;
    width: 300px;
    height: calc(100vh - 8rem);
    z-index: 999;
    overflow-y: auto;
    user-select: none;
    top: 6.5rem;
    left: 2rem;
    border-radius: 12px;
    transition: transform .2s, left .2s;
    background-color: var(--surface-overlay);
    padding: 0.5rem 1.5rem;
}

.layout-sidebar::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  
  .layout-sidebar::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 6px;
  }
  
  .layout-sidebar::-webkit-scrollbar-thumb {
    background-color: #D5D5D5;
    border-radius: 6px;
  }
  
  .layout-sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #D5D5D5;
  }

.layout-menu {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .layout-root-menuitem {
        >.layout-menuitem-root-text {
            font-size: .857rem;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--surface-900);
            margin: .75rem 0;
        }

        >a {
            display: none;
        }
    }

    a {
        user-select: none;

        &.active-menuitem {
            >.layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    li.active-menuitem {
        >a {
            .layout-submenu-toggler {
                transform: rotate(-180deg);
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        a {
            display: flex;
            align-items: center;
            position: relative;
            outline: 0 none;
            color: $text-color;
            cursor: pointer;
            font-size: 16px;
            padding: .75rem 1rem;
            border-radius: 6px;
            transition: background-color .2s, box-shadow .2s;

            .layout-menuitem-icon {
                margin-right: .9rem;
                display: flex;
                svg {
                    width: 16px;
                    height: 18px;
                }
            }

            .layout-submenu-toggler {
                font-size: 75%;
                margin-left: auto;
                transition: transform .2s;
            }

            svg {
                fill: #252525
            }

            &.active-route {
                font-weight: 600;
                color: $primary-color;
                background-color: $primary-color-light;

                svg {
                    fill: $primary-color;
                }
            }

            &:hover {
                color: $primary-color;
                background-color: $primary-color-light;
                svg {
                    fill: $primary-color;
                }
            }

            &:focus {
                box-shadow: none !important;
                @include focused-inset();
            }
        }

        ul {
            overflow: hidden;
            border-radius: $borderRadius;

            li {
                a {
                    margin-left: 1rem;
                }

                li {
                    a {
                        margin-left: 2rem;
                    }

                    li {
                        a {
                            margin-left: 2.5rem;
                        }

                        li {
                            a {
                                margin-left: 3rem;
                            }

                            li {
                                a {
                                    margin-left: 3.5rem;
                                }

                                li {
                                    a {
                                        margin-left: 4rem;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .submenu:nth-child(2) {
        a {
            &.active-route {
                color: $primary-color;
                background-color: transparent;
            }
        
            &:hover {
                color: $primary-color;
                background-color: transparent;
            }
        }
    }
}